<template>
    <v-form ref="form">
      <v-container>
        <v-row justify="start">
          <v-col cols="12" md="9">
            <BaseTextField
              :rules="[requiredRule]"
              v-model="vendorCategory.name"
              label="Name"
              placeholder="vendor category name"
              persistent-placeholder
            ></BaseTextField>
  
            <v-row align="center" justify="start">
              <v-btn
                class="my-5"
                color="accent"
                medium
                @click="emitSubmission()"
              >
                {{ formActionName }}
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </template>
  
  <script>
  import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";
  
  import { EventBus } from "@/main";
  
  export default {
    props: {
      vendorCategoryObject: Object,
      formActionName: String,
      isEdit: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        vendorCategory: this.vendorCategoryObject,
      };
    },
    watch: {
        vendorCategoryObject: {
        immediate: true,
        handler() {
          this.vendorCategory = this.vendorCategoryObject;
        },
      },
      vendorCategory: {
        deep: true,
        handler() {
          EventBus.$emit("formChanged");
        },
      },
    },
    mixins: [FormValidationRulesMixin],
    methods: {
      emitSubmission() {
        if (this.$refs.form.validate()) {
          EventBus.$emit("formSubmitted");
          this.$emit(
            "vendorCategorySubmitted",
            this.vendorCategory,
          );
        }
      },
    },
  };
  </script>
  
  