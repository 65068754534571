import {
  addVendorCategoryAPI,
  deleteVendorsCategoryAPI,
  getVendorCategoriesAPI,
  getVendorsCategoryAPI,
  updateVendorsCategoryAPI,
  updateVendorsCategoryStatusAPI,
} from "./VendorCategories.api";
import BaseService from "@/core/classes/base/BaseService";
import Client from "@/core/classes/client/Client";
// import Mapper from "@/core/classes/mapper/Mapper";

export default class VendorCategoriesService extends BaseService {
  static getVendorsCategories(params) {
    return Client.get(getVendorCategoriesAPI, params).then((res) =>
      Promise.resolve(res)
    );
  }
  static getVendorsCategory(categoryId) {
    return Client.get(getVendorsCategoryAPI(categoryId)).then((res) =>
      Promise.resolve(res)
    );
  }
  static addVendorCategory(categoryData) {
    return Client.post(addVendorCategoryAPI, categoryData).then((res) =>
      Promise.resolve(res)
    );
  }
  static updateVendorsCategory(categoryId, categoryData) {
    return Client.put(updateVendorsCategoryAPI(categoryId), categoryData).then(
      (res) => Promise.resolve(res)
    );
  }
  static updateVendorsCategoryStatus(categoryId, newStatus) {
    return Client.post(updateVendorsCategoryStatusAPI(categoryId), {
      is_active: newStatus,
    }).then((res) => Promise.resolve(res));
  }
  static deleteVendorsCategory(categoryId) {
    return Client.delete(deleteVendorsCategoryAPI(categoryId)).then((res) =>
      Promise.resolve(res)
    );
  }
}
