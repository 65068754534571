<template>
  <v-navigation-drawer
    color="primary"
    overlay-color="light"
    v-model="isDrawerVisible"
    width="275"
    height="100%"
    app
  >
    <!--drawer background-->
    <template v-slot:img>
      <v-img class="opacity-md"> </v-img>
    </template>
    <!--drawer header-->
    <template v-slot:prepend>
      <v-list-item height="100" class="mt-4">
        <v-spacer></v-spacer>
        <v-avatar class="elevation-15" color="white" size="100">
          <v-img contain src="@/assets/logos/logo.png" class="rounded"></v-img>
        </v-avatar>
        <v-spacer></v-spacer>
      </v-list-item>
      <v-list-item>
        <v-spacer></v-spacer>
        <h2 class="f-s-md primary--text text--darken-2">
          Hadyati Mall Dashboard
          {{ username ? username : "" }}
        </h2>
        <v-spacer></v-spacer>
      </v-list-item>
    </template>
    <!--drawer tabs-->
    <v-tabs center-active background-color="transparent" vertical optional>
      <v-tab class="f-s-md justify-start white--text" to="/">
        <v-icon class="mx-5"> mdi-home </v-icon>
        Home
      </v-tab>
      <v-tabs-slider color="white"></v-tabs-slider>
    </v-tabs>
    <v-expansion-panels flat focusable accordion>
      <!--BANNERS TABS-->
      <TabsExpansionPanel title="Banners management" icon="mdi-image-area" :tabs="bannerTabs" />
      <!--VENDORS TABS-->
      <TabsExpansionPanel title="Vendors management" icon="mdi-store" :tabs="vendorsTabs" />
      <!--PRODUCTS TABS-->
      <TabsExpansionPanel title="Products management" icon="mdi-package-variant" :tabs="productsTabs" />
      <!--Users TABS-->
      <TabsExpansionPanel title="Users management" icon="mdi-account-group" :tabs="usersTabs" />
      <!--Wallets TABS-->
      <TabsExpansionPanel title="Wallets management" icon="mdi-wallet-bifold" :tabs="walletsTabs" />
    </v-expansion-panels>

    <!--profile/logout tabs-->
    <template v-slot:append>
      <v-tabs class="mb-5" background-color="transparent" vertical optional>
        <!-- <v-tab class="f-s-md justify-start white--text" to="/profile">
          <v-icon class="mx-5"> mdi-account-circle </v-icon>
          Profile
        </v-tab> -->
        <v-tab
          class="f-s-md justify-start white--text"
          @click="showLogoutDialog()"
        >
          <v-icon class="mx-5"> mdi-logout </v-icon>
          Logout
        </v-tab>
      </v-tabs>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { useAuthStore } from "@/modules/auth/store";
import { useGlobalStore } from "@/store/GlobalStore";
import { mapActions, mapState, mapWritableState } from "pinia";
import TabsExpansionPanel from "./TabsExpansionPanel.vue";
import { bannerTabs,vendorsTabs,productsTabs,usersTabs,walletsTabs } from "./TabsList";

export default {
  components: { TabsExpansionPanel },
  data() {
    return {
      bannerTabs: bannerTabs,
      vendorsTabs:vendorsTabs,
      productsTabs:productsTabs,
      usersTabs:usersTabs,
      walletsTabs:walletsTabs,
    };
  },
  computed: {
    ...mapWritableState(useGlobalStore, ["isDrawerVisible"]),
    ...mapState(useAuthStore, ["username"]),
  },
  methods: {
    ...mapActions(useAuthStore, ["changeLogoutDialogVisibility"]),
    showLogoutDialog() {
      this.changeLogoutDialogVisibility();
    },
  },
};
</script>

<style scoped>
.opacity-md {
  opacity: 0.5;
}
.f-s-md {
  font-size: medium;
}
.justify-start {
  justify-content: start;
}
</style>
