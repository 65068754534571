import BannerCategory from "@/modules/banners_categories/models/BannerCategory";
import Vendor from "@/modules/vendors/models/Vendor";

export default class Banner {
  id;
  is_active;
  start_date;
  end_date;
  vendor = new Vendor();
  vendor_id;
  category = new BannerCategory();
  banner_category_id;
  created_at;
  image;
  images = [];
  constructor(data) {
    this.id = data?.id;
    this.start_date = data?.start_date;
    this.end_date = data?.end_date;
    this.is_active = data?.is_active;
    this.category = new BannerCategory(data?.banner_category);
    this.banner_category_id = data?.banner_category?.id;
    this.vendor = new Vendor(data?.vendor);
    this.vendor_id = data?.vendor?.id;
    this.image = data?.image;
    if (data?.images) {
      for (const [key, image] of Object.entries(data?.images)) {
        key;
        this.images.push(image);
      }
    }
    this.created_at = data?.created_at;
  }
}
