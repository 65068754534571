var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseDataTable',{attrs:{"headers":_vm.headers,"paginatedItems":_vm.paginatedWallets},on:{"debouncedSearch":function (searchKey) { return _vm.setSearchNFetch(searchKey); },"optionsChanged":function (options) { return _vm.setTableOptionsNFetch(options); }},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('ShowWalletButton',{attrs:{"Button":"","item":item}})]}},{key:"item.add_amount",fn:function(ref){
var item = ref.item;
return [_c('AddAmountForm',{attrs:{"item":item}})]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('RouterLink',{attrs:{"to":{ name: 'show_user', params: { id: item.user_id } }}},[_vm._v(" "+_vm._s(item.user_id)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }