import { defineStore } from "pinia";

import BaseStore from "@/core/classes/base/BaseStore";
import VendorCategoriesService from "../services/VendorCategoriesService";
import VendorCategoryKeyTransformation from "../models/VendorCategoryKeyTransformation";
import PaginatedVendorCategoryCollection from "../models/PaginatedVendorCategoryCollection";
import VendorCategory from "../models/VendorCategory";

export const useVendorCategoriesStore = defineStore("VendorCategoriesStore", {
  state: () => ({
    paginatedVendorCategory: new PaginatedVendorCategoryCollection(),
    vendorCategoriesTableOptions: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: -1,
    },
    searchKey: null,
    vendorCategory: null,
  }),
  getters: {
    getFilterSearchSortPageParameters() {
      var sortObj =
        this.vendorCategoriesTableOptions.sortBy.length != 0
          ? {
              sort_by:
                VendorCategoryKeyTransformation[
                  this.vendorCategoriesTableOptions.sortBy[0]
                ],
              sort_desc: this.vendorCategoriesTableOptions.sortDesc[0],
            }
          : null;
      return new Object({
        search_key: this.searchKey,
        items_per_page:
          this.vendorCategoriesTableOptions.itemsPerPage != -1
            ? this.vendorCategoriesTableOptions.itemsPerPage
            : null,
        page:
          this.vendorCategoriesTableOptions.itemsPerPage != -1
            ? this.vendorCategoriesTableOptions.page
            : null,
        ...sortObj,
      });
    },
  },
  actions: {
    setSearchNFetch(searchKey) {
      this.searchKey = searchKey;
      this.resetPaginatedCollection();
      this.fetchVendorCategories();
    },
    setTableOptionsNFetch(options) {
      this.vendorCategoriesTableOptions = options;
      this.fetchVendorCategories();
    },
    resetPaginatedCollection() {
      this.paginatedVendorCategory = new PaginatedVendorCategoryCollection();
    },
    removeVendorCategoryLocally(categoryId) {
      this.paginatedVendorCategory.collection.splice(
        this.paginatedVendorCategory.collection.findIndex(
          (category) => category.id === categoryId
        ),
        1
      );
    },
    toggleVendorCategoryActivationLocally(categoryId) {
      this.paginatedVendorCategory.collection.map((category) => {
        if (category.id == categoryId) category.is_active = !category.is_active;
      });
    },
    fetchVendorCategories() {
      return BaseStore.promiseHandler(
        () =>
          VendorCategoriesService.getVendorsCategories(
            this.getFilterSearchSortPageParameters
          ),
        (data) => {
          this.paginatedVendorCategory = new PaginatedVendorCategoryCollection(
            data
          );
        }
      );
    },
    createVendorCategory(categoryData) {
      return BaseStore.promiseHandler(() =>
        VendorCategoriesService.addVendorCategory(categoryData)
      );
    },
    editVendorCategory(categoryId, categoryData) {
      return BaseStore.promiseHandler(() =>
        VendorCategoriesService.updateVendorsCategory(categoryId, categoryData)
      );
    },
    fetchVendorCategory(categoryId) {
      return BaseStore.promiseHandler(
        () => VendorCategoriesService.getVendorsCategory(categoryId),
        (data) => {
          this.vendorCategory = new VendorCategory(data.data);
        }
      );
    },
    deleteVendorCategory(categoryId) {
      return BaseStore.promiseHandler(
        () => VendorCategoriesService.deleteVendorsCategory(categoryId),
        () => {
          this.removeVendorCategoryLocally(categoryId);
        }
      );
    },
    toggleVendorCategoryActivation(categoryId, newStatus) {
      return BaseStore.promiseHandler(
        () =>
          VendorCategoriesService.updateVendorsCategoryStatus(
            categoryId,
            newStatus
          ),
        () => {
          this.toggleVendorCategoryActivationLocally(categoryId);
        }
      );
    },
  },
});
