<template>
  <BaseDataTable
    :headers="headers"
    :paginatedItems="paginatedWallets"
    @debouncedSearch="(searchKey) => setSearchNFetch(searchKey)"
    @optionsChanged="(options) => setTableOptionsNFetch(options)"
  >
    <template v-slot:actions="{ item }">
      <ShowWalletButton Button :item="item" />
    </template>

    <template v-slot:[`item.add_amount`]="{ item }">
      <AddAmountForm :item="item" />
    </template>
    <!--TODO display user name-->
    <template v-slot:[`item.user`]="{ item }">
      <RouterLink :to="{ name: 'show_user', params: { id: item.user_id } }">
        {{ item.user_id }}
      </RouterLink>
    </template>
    <template v-slot:[`item.created_at`]="{ item }">
      {{ item.created_at | formatDate }}
    </template>
  </BaseDataTable>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useWalletsStore } from "../store/WalletsStore";
import ShowWalletButton from "./action_buttons/ShowWalletButton.vue";
import AddAmountForm from "./action_buttons/AddAmountForm.vue";
import { RouterLink } from "vue-router";
export default {
  components: {
    RouterLink,
    ShowWalletButton,
    AddAmountForm,
  },
  computed: {
    ...mapState(useWalletsStore, ["paginatedWallets"]),
    ...mapWritableState(useWalletsStore, ["searchKey"]),
  },
  data() {
    return {
      headers: [
        { text: "id", align: "start", value: "id" },
        { text: "user", value: "user" },
        { text: "amount", value: "amount" },
        { text: "add amount", sortable: false, value: "add_amount" },
        { text: "created at", value: "created_at" },
      ],
    };
  },
  methods: {
    ...mapActions(useWalletsStore, [
      "fetchWallets",
      "setSearchNFetch",
      "setTableOptionsNFetch",
    ]),
  },
  mounted() {
    this.fetchWallets()
      .then()
      .catch((err) => console.error(err));
  },
  destroyed() {
    this.searchKey = null;
  },
};
</script>
