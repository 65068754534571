export default class VendorCategory {
    id;
    name;
    is_active;
    created_at;
    vendors=[];
    constructor(data) {
      this.id = data?.id;
      this.name = data?.name;
      this.is_active = data?.is_active;
      this.created_at = data?.created_at;
      this.vendors=data?.vendors;
    }
  }
  