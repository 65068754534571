<template>
  <v-form ref="form">
    <v-container>
      <v-row justify="start">
        <v-col cols="12" md="9">
          <BaseDateField
            :rules="[requiredRule]"
            v-model="banner.start_date"
            label="Start date"
            placeholder="Enter banner start date"
            persistent-placeholder
          ></BaseDateField>

          <BaseDateField
            :rules="[requiredRule]"
            v-model="banner.end_date"
            label="End date"
            placeholder="Enter banner end date"
            persistent-placeholder
          ></BaseDateField>

          <BaseSelect
            :rules="[requiredRule]"
            :items="paginatedBannerCategory.collection"
            v-model="banner.banner_category_id"
            item-value="id"
            chips
            clearable
            label="category"
            placeholder="choose banner category"
          >
            <template v-slot:item="{ item }">
              {{ item.type }}
            </template>
            <template v-slot:selection="{ item }">
              <v-chip> {{ item.type }}</v-chip>
            </template>
          </BaseSelect>

          <BaseSelect
            :rules="[requiredRule]"
            :items="paginatedVendors.collection"
            v-model="banner.vendor_id"
            item-value="id"
            chips
            clearable
            label="vendor"
            placeholder="choose vendor"
          >
            <template v-slot:item="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:selection="{ item }">
              <v-chip> {{ item.name }}</v-chip>
            </template>
          </BaseSelect>
          <!--images--> 
          <template v-if="!isEdit">
            <!-- main image -->
            <v-file-input
              :rules="[requiredRule]"
              v-model="mainImage"
              accept="image/*"
              prepend-inner-icon="mdi-image"
              prepend-icon=""
              label="Main image"
              placeholder="input the main image of the banner"
              persistent-placeholder
              outlined
            >
              <template v-slot:selection>
                <div style="position: relative">
                  <v-btn
                    @click.stop="removeMainImage()"
                    fab
                    x-small
                    class="delete-icon"
                  >
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                  <v-img class="ma-2 rounded image" :src="mainImageUrl"></v-img>
                </div>
              </template>
            </v-file-input>

            <!-- other images -->
            <v-file-input
              v-model="images"
              accept="image/*"
              prepend-inner-icon="mdi-image"
              prepend-icon=""
              label="Other images"
              placeholder="input any other images of the banner"
              persistent-placeholder
              outlined
              multiple
            >
              <template v-slot:selection="data">
                <div style="position: relative">
                  <v-btn
                    @click.stop="removeImage(data.index)"
                    fab
                    x-small
                    class="delete-icon"
                  >
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                  <v-img
                    class="ma-2 rounded image"
                    :src="imageUrls[data.index]"
                  ></v-img>
                </div>
              </template>
            </v-file-input>
          </template>
          <template v-else>
            <!-- main image -->
            <v-file-input
              :rules="[requiredRule]"
              v-model="mainImage"
              accept="image/*"
              prepend-inner-icon="mdi-image"
              prepend-icon=""
              label="Main image"
              placeholder="input the main image of the banner"
              persistent-placeholder
              outlined
            >
              <template v-slot:selection>
                <div style="position: relative">
                  <v-btn
                    @click.stop="removeMainImage()"
                    fab
                    x-small
                    class="delete-icon"
                  >
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                  <v-img
                    class="ma-2 rounded image"
                    :src=" typeof mainImage == 'string' ? mainImage : mainImageUrl"
                  ></v-img>
                </div>
              </template>
            </v-file-input>

            <v-row align="center">
              <v-col cols="6">
                <h2 class="secondary--text my-3">uploaded images</h2>
              </v-col>
              <v-col cols="6">
                <v-btn dark color="primary" @click="toggleAddNewImage">
                  <v-icon class="mr-2">mdi-plus</v-icon> upload new image
                </v-btn></v-col
              >
            </v-row>
            <v-form v-if="showAddNewImage" ref="newImageForm">
              <v-row >
                <v-col align-self="center" cols="12" md="8" class="mr-0 pr-0">
                  <v-file-input
                    :rules="[requiredRule]"
                    v-model="newImage"
                    accept="image/*"
                    prepend-inner-icon="mdi-image"
                    prepend-icon=""
                    label="New image"
                    placeholder="upload a new image of the banner"
                    persistent-placeholder
                    outlined
                  >
                    <template v-slot:selection>
                      <div style="position: relative">
                        <v-btn
                          @click.stop="removeNewImage()"
                          fab
                          x-small
                          class="delete-icon"
                        >
                          <v-icon> mdi-close </v-icon>
                        </v-btn>
                        <v-img
                          class="ma-2 rounded image"
                          :src="newImageUrl"
                        ></v-img>
                      </div>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col cols="12" md="4" class="mr-0 pr-0">
                  <v-btn dark color="primary" @click="validateThenAddNewImage">
                    add
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>

            <v-row>
              <v-col cols="4" v-for="image in banner.images" :key="image.uuid">
                <div style="position: relative">
                  <v-btn
                    @click.stop="deletePreviousImage(image.uuid)"
                    fab
                    x-small
                    class="delete-icon"
                  >
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                  <v-img
                    class="ma-2 rounded image"
                    :src="image.original_url"
                  ></v-img>
                </div>
              </v-col>
            </v-row>
          </template>

          <v-row align="center" justify="start">
            <v-btn class="my-5" color="accent" medium @click="emitSubmission()">
              {{ formActionName }}
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";
import { mapActions, mapState } from "pinia";
import { useBannerCategoriesStore } from "../../../banners_categories/store/BannerCategoriesStore";
import { useBannersStore } from "../../store/BannersStore";
import { useVendorsStore } from "../../../vendors/store/VendorsStore";
import { EventBus } from "@/main";

export default {
  props: {
    bannerObject: Object,
    formActionName: String,
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      banner: this.bannerObject,
      mainImage: null,
      newImage: null,
      showAddNewImage: null,
      images: [],
    };
  },
  watch: {
    bannerObject: {
      immediate: true,
      handler() {
        this.banner = this.bannerObject;
      },
    },
    banner: {
      deep: true,
      handler() {
        EventBus.$emit("formChanged");
      },
    },
  },
  mounted() {
    Promise.all([this.fetchBannerCategories(false), this.fetchVendors(false)])
      .then(() => {
        if (this.isEdit) this.mainImage = this.banner.image;
      })
      .catch((err) => console.error(err));
  },
  computed: {
    imageUrls() {
      var urls = [];
      this.images.map((image) => urls.push(URL.createObjectURL(image)));
      return urls;
    },
    mainImageUrl() {
      return this.mainImage ? URL.createObjectURL(this.mainImage) : "";
    },
    newImageUrl() {
      return this.newImage ? URL.createObjectURL(this.newImage) : "";
    },
    ...mapState(useBannerCategoriesStore, ["paginatedBannerCategory"]),
    ...mapState(useVendorsStore, ["paginatedVendors"]),
  },
  mixins: [FormValidationRulesMixin],
  methods: {
    removeMainImage() {
      this.mainImage = null;
    },
    removeNewImage() {
      this.newImage = null;
    },
    removeImage(index) {
      this.images.splice(index, 1);
    },
    toggleAddNewImage() {
      this.showAddNewImage = !this.showAddNewImage;
    },
    ...mapActions(useBannerCategoriesStore, ["fetchBannerCategories"]),
    ...mapActions(useVendorsStore, ["fetchVendors"]),
    ...mapActions(useBannersStore, [
      "addMediaToBanner",
      "deleteMediaFromBanner",
    ]),
    validateThenAddNewImage() {
      if (this.$refs.newImageForm.validate()) {
        this.addMediaToBanner(this.banner.id, this.newImage)
          .then(()=>{
            this.$refs.newImageForm.reset()
          })
          .catch((err) => console.error(err));
      }
    },
    deletePreviousImage(imageId) {
      this.deleteMediaFromBanner(imageId)
        .then()
        .catch((err) => console.error(err));
    },
    emitSubmission() {
      if (this.$refs.form.validate()) {
       var emittedBanner = {
          ...this.banner,
          is_active: this.banner.is_active ? 1 : 0,
          image: this.mainImage,
          images:this.isEdit?this.banner.images: this.images,
        };
        if( typeof emittedBanner.image  == 'string'){
          emittedBanner.image=null;
        }
        if(this.isEdit){
          emittedBanner.images=null;
        }
        EventBus.$emit("formSubmitted");
        this.$emit("bannerSubmitted", emittedBanner);
      }
    },
  },
};
</script>

<style scoped>
.v-text-field {
  max-width: 450px;
}
.image {
  height: auto;
  max-width: 150px;
  border: 2px solid var(--v-primary-base);
}
.delete-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1000;
}
</style>
